<template>
<div>
  <div :key="this.$store.state.allUsers">
    <div class="text-center" v-if="loading">
      <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
    </div>
      <groupui-table size="s" v-bind:class="{ darkmode: this.$store.state.darkmode }">
        <groupui-table-row header>
          <groupui-table-cell>Email</groupui-table-cell>
        </groupui-table-row>
        <groupui-table-row v-show="!users">
          <groupui-table-cell>No Data </groupui-table-cell>
        </groupui-table-row>
        <groupui-table-row
          v-show="users"
          v-for="user in displayedUsers"
          :key="user.email"
        >
          <groupui-table-cell>
            <groupui-accordion
              expanded="false"
              first="false"
              size="s"
            >
              <span
                slot="headline"
              >
                <groupui-text size="normal" weight="bold">
                  {{user.email}}
                  <user-manager class="icon" v-if="user.accounts.filter(account => account.description == 'Owner' ).length > 0"/>
                  <wrench class="icon" v-if="user.mfa == 'false'" />
                  <smartphone-lock id="smartphone-lock" class="icon" v-else-if="user.mfa != null" />
                  <safetyvest-outline id="safetyvest-outline" class="icon" v-if="user.email == 'stefan.killian@man.eu'" />
                </groupui-text>
              </span>
              <li v-if="user.mfa !== 'false'">
                <groupui-text size="normal" weight="bold">MFA: </groupui-text><groupui-text size="normal">{{user.mfa}}</groupui-text>
              </li>
              <li v-show="Boolean(user.user_arn)">
                <groupui-text size="normal" weight="bold">ARN: </groupui-text><groupui-text size="normal">{{user.user_arn}}</groupui-text>
              </li>
              <li>
                <groupui-item>
                  <groupui-text size="normal" weight="bold">AWS Accounts:</groupui-text>
                </groupui-item>
                <groupui-item>
                  <groupui-text v-for="account in user.accounts" :key="account.account_id">
                    {{account.account_id}} - {{account.description}}
                  </groupui-text>
                </groupui-item>
              </li>
            </groupui-accordion>
          </groupui-table-cell>
        </groupui-table-row>
      </groupui-table>
      <!-- pagination -->
      <groupui-button-group
        inverted="false"
        variant="button"
        :key="page"
      >
        <groupui-button
          type="button"
          variant="tertiary"
          size="m"
          v-show="page != 1"
          @click="setPage('minus')"
        >
          Previous
        </groupui-button>
        <groupui-button
          type="button"
          variant="tertiary"
          size="m"
          v-for="pageNumber in pages"
          @click="setPage(pageNumber)"
          v-bind:key="pageNumber"
          v-bind:class="{'active-button': (page == pageNumber)}"
        >
          <groupui-text
            weight="bold"
            v-bind:class="[{'active-button': (page == pageNumber)}, 'not-active']"
            class="page-text"
          >
            {{pageNumber}}
          </groupui-text>
        </groupui-button>
        <groupui-button
          type="button"
          variant="tertiary"
          size="m"
          @click="setPage('plus')"
          v-show="page < pages.length"
        >
          Next
        </groupui-button>
      </groupui-button-group>
  </div>
  <accounts-info
    method="listAllAccounts"
    :accountsPerPage='10'
  />

</div>
</template>

<script>

import Analytics from '@aws-amplify/analytics';
import AccountsInfo from '../components/accounts-info';

export default {
  name: 'admin',
  data() {
    return {
      loading: true,

      users: [],
      page: 1,
      perPage: 20,
      pages: [],
    };
  },
  components: {
    'smartphone-lock': require('@community/group-ui-assets/icons/man/smartphone-lock-48.svg'),
    'user-manager': require('@community/group-ui-assets/icons/man/user-manager-24.svg'),
    'wrench': require('@community/group-ui-assets/icons/man/wrench-24.svg'),
    'safetyvest-outline': require('@community/group-ui-assets/icons/man/safetyvest-outline-48.svg'),
    AccountsInfo
  },
  async created() {
    await this.getUsersData();
    Analytics.record('Access_admin_page');
  },
  computed: {
    displayedUsers() {
      return this.paginate(this.users);
    },
  },
  watch: {
    users() {
      this.setPages();
    }
  },
  methods: {
    setPage(operation) {
      if ( operation == 'plus') {
        this.page++
      } else if ( operation == 'minus') {
        this.page--
      } else {
        this.page = operation
      }
      window.scrollTo(0,0);
    },
    async getUsersData() {
      this.loading = true;
      try {
        await this.$store.dispatch('listAllUsers');
        try {
          this.users = this.sortUsers(this.$store.getters.getAllUsers, 'email')
        }
        catch (error){
          this.users = {}
        }
      } catch (error) {
        console.log("Error loading Data...", error);
      } finally {
        this.loading = false;
      }
    },
    setPages() {
      let numberOfPages = Math.ceil(this.users.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(users) {
      let page = this.page;
      let perPage = this.perPage;
      let from = (page * perPage) - perPage;
      let to = (page * perPage);
      return  users.slice(from, to);
    },

    sortUsers(items, key){
      let sortedItems = items;

      sortedItems = sortedItems.sort((a,b) => {
        let fa = a[key].toLowerCase(), fb = b[key].toLowerCase();
        if (fa < fb) {
          return -1
        }
        if (fa > fb) {
          return 1
        }
        return 0
      })
      return sortedItems
    },
  }

};

</script>

<style>
</style>

<style scoped>
#smartphone-lock.icon {
  transform: scale(0.7);
}

#safetyvest-outline.icon {
  transform: scale(0.7);
}

.users {
  padding-top: 10px;
  height: 100%;
  font-size: x-large;
}

.label {
  font-weight: bold;
}

.user_email {
  border-bottom:0px
}

.page-text {
  color: var(--groupui-man-color-silver-50);
}

.active-button {
  color: var(--groupui-man-color-red-500-primary);
}

li {
  list-style-type: none;
}

.offset{
  width: 500px !important;
  margin: 20px auto;
}

div.accounts {
  padding-top: 3em;
}

</style>

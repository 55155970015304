<template>
  <div class="accounts" :key="watchAccounts">
    <div class="text-center" v-if="loading">
        <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
    </div>
    <groupui-table size="s" v-bind:class="{ darkmode: this.$store.state.darkmode }">
    <groupui-table-row header>
      <groupui-table-cell>Account</groupui-table-cell>
    </groupui-table-row>
    <groupui-table-row v-show="!accounts">
      <groupui-table-cell>No Data </groupui-table-cell>
    </groupui-table-row>
    <groupui-table-row v-show="accountPerm.account" v-for="accountPerm in displayedAccounts" :key="accountPerm.account.accountId" class="account">
      <groupui-table-cell>
        <groupui-accordion expanded="false" first="false" size="s">
          <span slot="headline">
            <groupui-text size="normal">
              <strong>{{accountPerm.account.name}}</strong>
              ({{accountPerm.account.accountId}})
            </groupui-text>
          </span>
          <groupui-text size="normal" weight="bold">
            {{accountPerm.account.description}}
          </groupui-text>

            <ul>
              <li>
                <span class="label" v-show="accountPerm.description">Permission:</span> {{accountPerm.description}}
              </li>
            </ul>

            <ul>
              <li>
                <span class="label">Creation date:</span> {{accountPerm.account.createDate}}
              </li>
              <li>
                <span class="label" v-show="accountPerm.account.rootEmail">Root e-mail:</span> {{accountPerm.account.rootEmail}}
              </li>
              <li>
                <span class="label">Stage:</span> {{accountPerm.account.stage}}
              </li>
              <li>
                <span class="label">Type:</span> {{accountPerm.account.accountType}}
              </li>
              <li>
                <span class="label">Business service:</span> {{accountPerm.account.businessService}}
              </li>
              <li v-if="visibleFor(['cloudAdmin', 'accountOwner', 'itManager'], accountPerm)">
                <span class="label">Master account:</span> {{accountPerm.account.masterAccount}}
              </li>
              <li v-if="visibleFor(['cloudAdmin', 'accountOwner', 'itManager'], accountPerm)">
                <span class="label">PSP Element:</span> {{accountPerm.account.pspElement}}
              </li>
               <li v-if="accountPerm.account.accountAlias">
                <span class="label">Alias:</span> {{accountPerm.account.accountAlias}}
              </li>
              <li v-if="accountPerm.account.accountOrgOU">
                <span class="label">Organizational Unit:</span> {{accountPerm.account.accountOrgOU}}
              </li>
            </ul>

            <div v-if="visibleFor(['cloudAdmin', 'accountOwner', 'itManager'], accountPerm)">
              <ul v-if="accountPerm.account.contacts">
                <li v-if="accountPerm.account.contacts.applicationOwner">
                  <span class="label">Application owner contact:</span> {{accountPerm.account.contacts.applicationOwner.email}}
                </li>
                <li v-if="accountPerm.account.contacts.serviceOwner">
                  <span class="label">Service owner contact:</span> {{accountPerm.account.contacts.serviceOwner.email}}
                </li>
                <li v-if="accountPerm.account.contacts.controlling">
                  <span class="label">Controlling contact:</span> {{accountPerm.account.contacts.controlling.email}}
                </li>
              </ul>
            </div>

            <ul v-if="accountPerm.account.budgetSettings">
              <li>
                <span class="label">Budget Limit:</span> {{accountPerm.account.budgetSettings.limit}}
              </li>
            </ul>

            <div v-if="visibleFor(['cloudAdmin', 'accountOwner', 'developer'], accountPerm)">
                <!-- Hack... Use of unordered-list just to ensure elements are kept aligned -->
                <ul v-if="accountPerm.account.networks.length > 0">
                  <li>
                    <groupui-table size="s">
                      <groupui-table-row header>
                        <groupui-table-cell>CIDR Block</groupui-table-cell>
                        <groupui-table-cell>VPC ID</groupui-table-cell>
                        <groupui-table-cell>Created at</groupui-table-cell>
                      </groupui-table-row>
                      <groupui-table-row v-for="network in accountPerm.account.networks" :key="network.cidr">
                        <groupui-table-cell>{{network.cidr}}</groupui-table-cell>
                        <groupui-table-cell>{{network.vpcId}}</groupui-table-cell>
                        <groupui-table-cell>{{network.createDate}}</groupui-table-cell>
                      </groupui-table-row>
                    </groupui-table>
                  </li>
                </ul>

              <!-- Hack... Use of unordered-list just to ensure elements are kept aligned -->
              <ul v-if="accountPerm.account.dnsZones.length > 0">
                <li>
                  <groupui-table size="s">
                    <groupui-table-row header>
                      <groupui-table-cell>Zone name</groupui-table-cell>
                      <groupui-table-cell>Zone ID</groupui-table-cell>
                      <groupui-table-cell>Nameservers</groupui-table-cell>
                      <groupui-table-cell>Created at</groupui-table-cell>
                    </groupui-table-row>
                    <groupui-table-row v-for="dnsZone in accountPerm.account.dnsZones" :key="dnsZone.hostedZoneId">
                      <groupui-table-cell>{{dnsZone.zoneName}}</groupui-table-cell>
                      <groupui-table-cell>{{dnsZone.hostedZoneId}}</groupui-table-cell>
                      <groupui-table-cell>
                        <ul class="nameserversTable">
                          <li v-for="ns in dnsZone.zoneDetails.delegationSet.nameServers" :key="ns">{{ns}}</li>
                        </ul>
                      </groupui-table-cell>
                      <groupui-table-cell>{{dnsZone.createDate}}</groupui-table-cell>
                    </groupui-table-row>
                  </groupui-table>
                </li>
              </ul>
            </div>

            <!-- Hack... Use of unordered-list just to ensure elements are kept aligned -->
            <ul v-if="accountPerm.account.costs.length > 0">
              <li>
                <groupui-table size="s">
                  <groupui-table-row header>
                    <groupui-table-cell>Date</groupui-table-cell>
                    <groupui-table-cell>Blended costs</groupui-table-cell>
                  </groupui-table-row>
                  <groupui-table-row v-for="cost in accountPerm.account.costs" :key="cost.date">
                    <groupui-table-cell>{{cost.date}}</groupui-table-cell>
                    <groupui-table-cell>{{cost.blendedCosts}}</groupui-table-cell>
                  </groupui-table-row>
                </groupui-table>
              </li>
            </ul>

            <div v-if="visibleFor(['cloudAdmin', 'accountOwner', 'developer'], accountPerm)">
              <!-- Hack... Use of unordered-list just to ensure elements are kept aligned -->
              <ul v-if="accountPerm.account.serviceUsers.length > 0">
                <li>
                  <groupui-table size="s">
                    <groupui-table-row header>
                      <groupui-table-cell>ARN</groupui-table-cell>
                      <groupui-table-cell>Username</groupui-table-cell>
                      <groupui-table-cell>Created at</groupui-table-cell>
                      <groupui-table-cell>Proposer</groupui-table-cell>
                      <groupui-table-cell>Comment</groupui-table-cell>
                    </groupui-table-row>
                    <groupui-table-row v-for="user in accountPerm.account.serviceUsers" :key="user.arn">
                      <groupui-table-cell>{{user.arn}}</groupui-table-cell>
                      <groupui-table-cell>{{user.userName}}</groupui-table-cell>
                      <groupui-table-cell>{{user.createDate}}</groupui-table-cell>
                      <groupui-table-cell>{{user.createProposer}}</groupui-table-cell>
                      <groupui-table-cell>{{user.comment}}</groupui-table-cell>
                    </groupui-table-row>
                  </groupui-table>
                </li>
              </ul>
            </div>
          </groupui-accordion>
        </groupui-table-cell>
      </groupui-table-row>
    </groupui-table>

    <!-- pagination -->
    <groupui-button-group inverted="false" variant="button" :key="accountsPage">
      <groupui-button type="button" variant="tertiary" size="m" v-show="accountsPage != 1"
        @click="setAccountsPage('minus')">
        Previous
      </groupui-button>
      <groupui-button type="button" variant="tertiary" size="m" v-for="pageNumber in accountsPages"
        @click="setAccountsPage(pageNumber)"
        v-bind:key="pageNumber"
        v-bind:class="{'active-button': (accountsPage == pageNumber)}">
        <groupui-text weight="bold" v-bind:class="[{'active-button': (accountsPage == pageNumber)}, 'not-active']" class="page-text">
          {{pageNumber}}
        </groupui-text>
      </groupui-button>
      <groupui-button type="button" variant="tertiary" size="m"
        @click="setAccountsPage('plus')"
        v-show="accountsPage < accountsPages.length">
        Next
      </groupui-button>
    </groupui-button-group>

  </div>
</template>

<script>
export default {
  name: "AccountsInfo",
  props: {
      method: String,
      'accountsPerPage': Number,
  },
  data() {
    return {
      loading: true,
      accounts: [],
      accountsPage: 1,
      accountsPages: [],
      tmpAcctRole: '',
    };
  },
  async created() {
    await this.getData();
  },
  computed: {
    displayedAccounts() {
      return this.paginateAccounts(this.accounts);
    },
    watchAccounts() {
      if (this.method == 'listMyAccounts'){
        return this.$store.myAccounts
      }else{
        return this.$store.allAccounts
      }
    },
  },
  watch: {
    accounts() {
      this.setAccountPages();
    }
  },
  methods: {
    setPage(operation) {
      if ( operation == 'plus') {
        this.page++
      } else if ( operation == 'minus') {
        this.page--
      } else {
        this.page = operation
      }
      window.scrollTo(0,0);
    },

    sortItems(items, key){
      let sortedItems = items;

      sortedItems = sortedItems.sort((a,b) => {
        let fa = a.account[key].toLowerCase(), fb = b.account[key].toLowerCase();
        if (fa < fb) {
          return -1
        }
        if (fa > fb) {
          return 1
        }
        return 0
      })
      return sortedItems
    },

    async getData() {
        this.loading = true
        await this.$store.dispatch(this.method);
        if (this.method == 'listMyAccounts'){
          this.accounts = this.sortItems(this.$store.getters.getMyAccounts, 'name')
        }else{
          this.accounts = this.sortItems(this.$store.getters.getAllAccounts, 'name')
        }
        this.loading = false;
    },
    setAccountPages() {
      let numberOfPages = Math.ceil(this.accounts.length / this.accountsPerPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.accountsPages.push(index);
      }
    },
    setAccountsPage(operation) {
      switch(operation) {
        case 'plus':
          this.accountsPage++;
          break;
        case 'minus':
          this.accountsPage--;
          break;
        default:
          this.accountsPage = operation;
      }
      let accountsTableElem = document.querySelector("div.accounts");
      if (accountsTableElem) {
        accountsTableElem.scrollIntoView();
      }
    },
    paginateAccounts(accounts) {
      let page = this.accountsPage;
      let perPage = this.accountsPerPage;
      let from = (page - 1) * perPage;
      let to = (page * perPage);
      return  accounts.slice(from, to);
    },
    userAccountRole(acctPerm) {
      if (this.$store.state.isAdmin) {
        return 'cloudAdmin';
      }

      if (this.$store.state.isManager) {
        return 'itManager';
      }

      switch(acctPerm.level) {
        case "50":
            return 'developer';
        case "100":
            return 'accountOwner';
      }
      return '';
    },
    visibleFor(roleList, acctPerm) {
      let userRole = this.userAccountRole(acctPerm);
      return roleList.includes(userRole);
    },
  }
};
</script>

<style scoped>
.users {
  padding-top: 10px;
  height: 100%;
  font-size: x-large;
}

.label {
  font-weight: bold;
}

.user_email {
  border-bottom:0px
}

.page-text {
  color: var(--groupui-man-color-silver-50);
}

.active-button {
  color: var(--groupui-man-color-red-500-primary);
}

li {
  list-style-type: none;
}

.offset{
  width: 500px !important;
  margin: 20px auto;
}


groupui-table-row.account ul {
  list-style-type: none;
}

ul.nameserversTable {
  padding: 0;
}
</style>